import { loadManifest } from '@angular-architects/module-federation';
import { ENV } from '@app/env';

if (ENV.environment === "Dev") {
  loadManifest("/assets/mf.manifest-dev.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err)); 
}
if (ENV.environment === "Local") {
  loadManifest("/assets/mf.manifest.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err)); 
}
if (ENV.environment === "Test") {
  loadManifest("/assets/mf.manifest-test.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err)); 
}
if (ENV.environment === "Cert") {
  loadManifest("/assets/mf.manifest-cert.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err)); 
}
if (ENV.environment === "Live") {
  loadManifest("/assets/mf.manifest-prod.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err)); 
} 